export default defineI18nConfig(() => ({
    legacy: false,
    locale: 'de',
    fallbackLocale: 'de',
    datetimeFormats: {
        'de': {
            date: {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            },
            datetime: {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            },
        },
    },
}));
  